import { ConfirmDeleteDialogComponent } from '../../shared/dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@jsverse/transloco';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { EventEmitter, Injectable } from '@angular/core';
import { ConfirmDeleteDialogData } from 'src/app/shared/dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { MAX_SECONDS_SPINNERS, USER_DEFAULT_IMAGE } from '../config/config.model';

@Injectable({
  providedIn: 'root',
})
export class UiService {
  user_image: string = USER_DEFAULT_IMAGE;

  _onShowSpinner: EventEmitter<boolean> = new EventEmitter<boolean>();
  _onShowSidenav: EventEmitter<boolean> = new EventEmitter<boolean>();
  _onShowSidenavSimulations: EventEmitter<boolean> = new EventEmitter<boolean>();
  _onLiveStatusChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  _onUpdateSidenavElements: EventEmitter<void> = new EventEmitter<void>();

  _spinnerTimer?: ReturnType<typeof setTimeout>;

  onUserImageUpdated: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private router: Router,
    private toast: ToastrService,
    private translate: TranslocoService,
    private toastrService: ToastrService,
    private dialog: MatDialog
  ) {
    this.onUserImageUpdated.subscribe(new_image => (this.user_image = new_image));
  }

  set showSpinner(new_value: boolean) {
    if (this._spinnerTimer) {
      clearTimeout(this._spinnerTimer);
      delete this._spinnerTimer;
    }

    if (new_value) {
      this._spinnerTimer = setTimeout(async () => {
        this._onShowSpinner.emit(false);
        if (environment.production) this.router.navigate(['logout']);
        else
          this.toast.error(this.translate.translate('utils.spinner_error', { seconds: MAX_SECONDS_SPINNERS }), '', {
            progressBar: true,
            closeButton: true,
            positionClass: 'toast-bottom-right',
            timeOut: 3000,
          });
      }, MAX_SECONDS_SPINNERS * 1000);
    }
    this._onShowSpinner.next(new_value);
  }

  set liveStatus(new_value: boolean) {
    this._onLiveStatusChange.emit(new_value);
  }

  set showSidenav(new_value: boolean) {
    this._onShowSidenav.emit(new_value);
  }

  set showSidenavSimulations(new_value: boolean) {
    this._onShowSidenavSimulations.emit(new_value);
  }

  updateSidenavElements(): void {
    this._onUpdateSidenavElements.next();
  }

  public withSpinner<T>(obs: Observable<T>): Observable<T> {
    this.showSpinner = true;
    return obs.pipe(finalize(() => (this.showSpinner = false)));
  }

  successToast(settings: {
    title?: string;
    message?: string;
    progressBar?: boolean;
    closeButton?: boolean;
    timeOut?: number;
    oneLine?: boolean;
  }): ActiveToast<any> {
    settings.progressBar = settings.progressBar ?? true;
    settings.closeButton = settings.closeButton ?? true;
    settings.timeOut = settings.timeOut ?? 7000;
    return this.toastrService.success(settings.message, settings.title, {
      progressBar: settings.progressBar,
      closeButton: settings.closeButton,
      positionClass: 'toast-bottom-right',
      timeOut: settings.timeOut,
      toastClass: settings.oneLine ? 'ngx-toastr-one-line ngx-toastr' : 'ngx-toastr',
    });
  }

  errorToast(settings: {
    title?: string;
    message?: string;
    progressBar?: boolean;
    closeButton?: boolean;
    timeOut?: number;
    oneLine?: boolean;
  }): ActiveToast<any> {
    settings.progressBar = settings.progressBar ?? true;
    settings.closeButton = settings.closeButton ?? true;
    settings.timeOut = settings.timeOut ?? 7000;
    return this.toastrService.error(settings.message, settings.title, {
      progressBar: settings.progressBar,
      closeButton: settings.closeButton,
      positionClass: 'toast-bottom-right',
      timeOut: settings.timeOut,
      toastClass: settings.oneLine ? 'ngx-toastr-one-line ngx-toastr' : 'ngx-toastr',
    });
  }

  errorToastGeneric(): ActiveToast<any> {
    return this.errorToast({ title: this.translate.translate('utils.errors.general') });
  }

  successMessage(message: string): ActiveToast<any> {
    return this.successToast({ title: this.translate.translate(message) });
  }

  errorMessage(message: string): ActiveToast<any> {
    return this.errorToast({ title: this.translate.translate(message) });
  }

  warningToast(settings: {
    title?: string;
    message?: string;
    progressBar?: boolean;
    closeButton?: boolean;
    timeOut?: number;
    oneLine?: boolean;
  }): ActiveToast<any> {
    settings.progressBar = settings.progressBar ?? true;
    settings.closeButton = settings.closeButton ?? true;
    settings.timeOut = settings.timeOut ?? 7000;
    return this.toastrService.warning(settings.message, settings.title, {
      progressBar: settings.progressBar,
      closeButton: settings.closeButton,
      positionClass: 'toast-bottom-right',
      timeOut: settings.timeOut,
      toastClass: settings.oneLine ? 'ngx-toastr-one-line ngx-toastr' : 'ngx-toastr',
    });
  }

  infoToast(settings: {
    title?: string;
    message?: string;
    progressBar?: boolean;
    closeButton?: boolean;
    timeOut?: number;
    oneLine?: boolean;
  }): ActiveToast<any> {
    settings.progressBar = settings.progressBar ?? true;
    settings.closeButton = settings.closeButton ?? true;
    settings.timeOut = settings.timeOut ?? 7000;
    return this.toastrService.info(settings.message, settings.title, {
      progressBar: settings.progressBar,
      closeButton: settings.closeButton,
      positionClass: 'toast-bottom-right',
      timeOut: settings.timeOut,
      toastClass: settings.oneLine ? 'ngx-toastr-one-line ngx-toastr' : 'ngx-toastr',
    });
  }

  public confirmDelete(data: ConfirmDeleteDialogData): Observable<boolean> {
    return this.dialog
      .open(ConfirmDeleteDialogComponent, {
        data,
        maxWidth: '28vw',
      })
      .afterClosed();
  }
}
