import type { AnimationItem } from 'lottie-web';
import { SimulationCardModel } from '../interfaces/simulation-card.model';
import { RouterLink } from '../../shared/sidenav/model/sidenav.model';

// Simulation

export type ConfigSimulationName = keyof ConfigSimulationModel;

export interface ConfigSimulationModel {
  _DEFAULT_DURATION_TEST: number;
  _DEFAULT_QUESTION_COUNT_TEST: number;
  _DURATION_EACH_QUESTION: number;
  _simulations_prefab: {};
  tests_lables: { lables: Array<string>; whitelist: boolean } | undefined;
  simulations_lables: { lables: Array<string>; whitelist: boolean } | undefined;
  challenge_lables: { lables: Array<string>; whitelist: boolean } | undefined; // no usage
  lives_lables: { lables: Array<string>; whitelist: boolean } | undefined;
  alice_lables: { lables: Array<string>; whitelist: boolean } | undefined;
  archive_lables: { lables: Array<string>; whitelist: boolean } | undefined;
  max_real_test_points: number;
  _custom_simulations: CustomSimulation;
  light_cards_simulations: Array<SimulationCardModel>;
  _homepage: string;
}

export interface CustomSimulation {
  title: string;
  subtitle: string;
  ssh?: string;
  type: 'prefab' | 'review_errors' | 'training' | 'custom' | 'admin_custom';
  simulation_type: string;
  category_path: string;
  affect_stats: boolean;
  prefab_uid: string;
  prefab_name: string;
  image: string;
  duration: number;
  custom_classes: string;
  animation?: AnimationItem;
  lables: Array<string>;
  lables_whitelist: boolean;
  hidden?: boolean;
  questions_each_category: Array<{ category_path: string; questions_count: number }>;
}

// UI

export type ConfigUiName = keyof ConfigUiModel;

export interface ConfigUiModel {
  _categories: Array<CfgCategoryInfo>;
  _dashboard_linear_chart: undefined | null | unknown; // no usage
  _dashboard_radar_chart: undefined | null | unknown; // no usage
  _leaderboards_icons: string; // no usage
  _site_available: string; // no usage
  _group_share_available: Array<{ link: string; name: string; type: string }>;
  live_options: Array<{ html: string; class: string; link: string; image: string }>; // no usage
  _colors: { categories: Record<string, string> };
  _test_category_cat_selected: number;
  radar_chart_filter: { all: boolean; categories: Array<string> }; // no usage
  _course_link: string;
  _dashboard_video: string; // no usage
  _hidden_sidenav_elements: Array<RouterLink> | undefined;
  _dashboard_simulation: string; // no usage
  _products_bg_url: string;
  _dashboard_slides: Array<{ title: string; lottie: string; content: string; button_type: string }>; // no usage
  _self_evaluation_category: string;
  _max_user_devices: number;
  _days_delete_device: number;
  _course_add_total_duration: number;
  _customer_care_phone_number: string;
  _spinner_url: string;
  _archive_show_cat3: string; // no usage
  _archive_show_card_cat1: string; // no usage
  _archive_show_cat2_in_cat3: string; // no usage
  _archive_show_cat1_in_cat2: string; // no usage
  _adaptive_video_in_explanation: string;
  _faq_link: string;
  _bg_image: string;
  _metatags: Array<{ key: string; value: string }>;
  _section_license: Array<{ section: string; lables: Array<string>; whitelist: boolean }> | undefined; // no usage
  _hide_cat2: boolean;
  _count_subject_to_show: number;
}

export interface CfgCategoryInfo {
  category_name: string;
  display_name: string;
  short_name: string;
  image: string;
  colors: Array<string>;
}

export const all_category: CfgCategoryInfo = {
  category_name: '',
  display_name: 'Generale',
  short_name: 'Generale',
  image: '',
  colors: ['#E3EA15', '#2C2E3B'],
};

export const USER_DEFAULT_IMAGE =
  'https://media-exp1.licdn.com/dms/image/C560BAQHadXBlb1nwVA/company-logo_200_200/0/1620384969482?e=1671062400&v=beta&t=hGRe68-2LeWzfazAVRfZnaBfSnJ5cQTj92RBysX2L-E';
export const MAX_SECONDS_SPINNERS = 5 * 60; // 5 minutes

export interface Config {
  leaderboard: {
    days_test_limit: number;
    minimum_questions_count: number;
    show_top: number;
  };
}

export const DEFAULT_HOMEPAGE = 'simulations';
